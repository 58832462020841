import React from 'react';

export default function Footer() {
  return (
    <footer className='footer'>
      <span>
        <a
          href='https://github.com/devsahinur'
          target='_blank'
          rel='noreferrer'
        >
          Made by{' '}
          <span style={{ 'color': '#A81020' }}>
            <i className='fas fa-heart'></i>
          </span>{' '}
          Sahinur Islam
        </a>
      </span>
    </footer>
  );
}

import React from "react";
import styled from "styled-components";

function Intro() {
  return (
    <LoadingContainer>
      <svg id="logo" width="602" height="114" viewBox="0 0 602 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="-0.151367" y="0.335999" width="602" height="113" fill="black">
      <rect fill="white" x="-0.151367" y="0.335999" width="602" height="113"/>
      <path d="M40.5526 108.008C33.9286 108.008 27.9766 106.856 22.6966 104.552C17.5126 102.152 13.4326 98.888 10.4566 94.76C7.48063 90.536 5.94463 85.688 5.84863 80.216H19.8166C20.2966 84.92 22.2166 88.904 25.5766 92.168C29.0326 95.336 34.0246 96.92 40.5526 96.92C46.7926 96.92 51.6886 95.384 55.2406 92.312C58.8886 89.144 60.7126 85.112 60.7126 80.216C60.7126 76.376 59.6566 73.256 57.5446 70.856C55.4326 68.456 52.7926 66.632 49.6246 65.384C46.4566 64.136 42.1846 62.792 36.8086 61.352C30.1846 59.624 24.8566 57.896 20.8246 56.168C16.8886 54.44 13.4806 51.752 10.6006 48.104C7.81663 44.36 6.42463 39.368 6.42463 33.128C6.42463 27.656 7.81663 22.808 10.6006 18.584C13.3846 14.36 17.2726 11.096 22.2646 8.792C27.3526 6.488 33.1606 5.336 39.6886 5.336C49.0966 5.336 56.7766 7.688 62.7286 12.392C68.7766 17.096 72.1846 23.336 72.9526 31.112H58.5526C58.0726 27.272 56.0566 23.912 52.5046 21.032C48.9526 18.056 44.2486 16.568 38.3926 16.568C32.9206 16.568 28.4566 18.008 25.0006 20.888C21.5446 23.672 19.8166 27.608 19.8166 32.696C19.8166 36.344 20.8246 39.32 22.8406 41.624C24.9526 43.928 27.4966 45.704 30.4726 46.952C33.5446 48.104 37.8166 49.448 43.2886 50.984C49.9126 52.808 55.2406 54.632 59.2726 56.456C63.3046 58.184 66.7606 60.92 69.6406 64.664C72.5206 68.312 73.9606 73.304 73.9606 79.64C73.9606 84.536 72.6646 89.144 70.0726 93.464C67.4806 97.784 63.6406 101.288 58.5526 103.976C53.4646 106.664 47.4646 108.008 40.5526 108.008Z"/>
      <path d="M152.572 84.68H108.796L100.732 107H86.9083L123.196 7.208H138.316L174.46 107H160.636L152.572 84.68ZM148.828 74.024L130.684 23.336L112.54 74.024H148.828Z"/>
      <path d="M267.604 6.632V107H254.5V61.496H203.38V107H190.276V6.632H203.38V50.696H254.5V6.632H267.604Z"/>
      <path d="M303.083 6.632V107H289.979V6.632H303.083Z"/>
      <path d="M404.328 107H391.224L338.52 27.08V107H325.416V6.488H338.52L391.224 86.264V6.488H404.328V107Z"/>
      <path d="M439.482 6.632V70.136C439.482 79.064 441.642 85.688 445.962 90.008C450.378 94.328 456.474 96.488 464.25 96.488C471.93 96.488 477.93 94.328 482.25 90.008C486.666 85.688 488.874 79.064 488.874 70.136V6.632H501.978V69.992C501.978 78.344 500.298 85.4 496.938 91.16C493.578 96.824 489.018 101.048 483.258 103.832C477.594 106.616 471.21 108.008 464.106 108.008C457.002 108.008 450.57 106.616 444.81 103.832C439.146 101.048 434.634 96.824 431.274 91.16C428.01 85.4 426.378 78.344 426.378 69.992V6.632H439.482Z"/>
      <path d="M576.686 107L552.782 65.96H536.942V107H523.838V6.632H556.238C563.822 6.632 570.206 7.928 575.39 10.52C580.67 13.112 584.606 16.616 587.198 21.032C589.79 25.448 591.086 30.488 591.086 36.152C591.086 43.064 589.07 49.16 585.038 54.44C581.102 59.72 575.15 63.224 567.182 64.952L592.382 107H576.686ZM536.942 55.448H556.238C563.342 55.448 568.67 53.72 572.222 50.264C575.774 46.712 577.55 42.008 577.55 36.152C577.55 30.2 575.774 25.592 572.222 22.328C568.766 19.064 563.438 17.432 556.238 17.432H536.942V55.448Z"/>
      </mask>
      <path d="M40.5526 108.008C33.9286 108.008 27.9766 106.856 22.6966 104.552C17.5126 102.152 13.4326 98.888 10.4566 94.76C7.48063 90.536 5.94463 85.688 5.84863 80.216H19.8166C20.2966 84.92 22.2166 88.904 25.5766 92.168C29.0326 95.336 34.0246 96.92 40.5526 96.92C46.7926 96.92 51.6886 95.384 55.2406 92.312C58.8886 89.144 60.7126 85.112 60.7126 80.216C60.7126 76.376 59.6566 73.256 57.5446 70.856C55.4326 68.456 52.7926 66.632 49.6246 65.384C46.4566 64.136 42.1846 62.792 36.8086 61.352C30.1846 59.624 24.8566 57.896 20.8246 56.168C16.8886 54.44 13.4806 51.752 10.6006 48.104C7.81663 44.36 6.42463 39.368 6.42463 33.128C6.42463 27.656 7.81663 22.808 10.6006 18.584C13.3846 14.36 17.2726 11.096 22.2646 8.792C27.3526 6.488 33.1606 5.336 39.6886 5.336C49.0966 5.336 56.7766 7.688 62.7286 12.392C68.7766 17.096 72.1846 23.336 72.9526 31.112H58.5526C58.0726 27.272 56.0566 23.912 52.5046 21.032C48.9526 18.056 44.2486 16.568 38.3926 16.568C32.9206 16.568 28.4566 18.008 25.0006 20.888C21.5446 23.672 19.8166 27.608 19.8166 32.696C19.8166 36.344 20.8246 39.32 22.8406 41.624C24.9526 43.928 27.4966 45.704 30.4726 46.952C33.5446 48.104 37.8166 49.448 43.2886 50.984C49.9126 52.808 55.2406 54.632 59.2726 56.456C63.3046 58.184 66.7606 60.92 69.6406 64.664C72.5206 68.312 73.9606 73.304 73.9606 79.64C73.9606 84.536 72.6646 89.144 70.0726 93.464C67.4806 97.784 63.6406 101.288 58.5526 103.976C53.4646 106.664 47.4646 108.008 40.5526 108.008Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      <path d="M152.572 84.68H108.796L100.732 107H86.9083L123.196 7.208H138.316L174.46 107H160.636L152.572 84.68ZM148.828 74.024L130.684 23.336L112.54 74.024H148.828Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      <path d="M267.604 6.632V107H254.5V61.496H203.38V107H190.276V6.632H203.38V50.696H254.5V6.632H267.604Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      <path d="M303.083 6.632V107H289.979V6.632H303.083Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      <path d="M404.328 107H391.224L338.52 27.08V107H325.416V6.488H338.52L391.224 86.264V6.488H404.328V107Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      <path d="M439.482 6.632V70.136C439.482 79.064 441.642 85.688 445.962 90.008C450.378 94.328 456.474 96.488 464.25 96.488C471.93 96.488 477.93 94.328 482.25 90.008C486.666 85.688 488.874 79.064 488.874 70.136V6.632H501.978V69.992C501.978 78.344 500.298 85.4 496.938 91.16C493.578 96.824 489.018 101.048 483.258 103.832C477.594 106.616 471.21 108.008 464.106 108.008C457.002 108.008 450.57 106.616 444.81 103.832C439.146 101.048 434.634 96.824 431.274 91.16C428.01 85.4 426.378 78.344 426.378 69.992V6.632H439.482Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      <path d="M576.686 107L552.782 65.96H536.942V107H523.838V6.632H556.238C563.822 6.632 570.206 7.928 575.39 10.52C580.67 13.112 584.606 16.616 587.198 21.032C589.79 25.448 591.086 30.488 591.086 36.152C591.086 43.064 589.07 49.16 585.038 54.44C581.102 59.72 575.15 63.224 567.182 64.952L592.382 107H576.686ZM536.942 55.448H556.238C563.342 55.448 568.67 53.72 572.222 50.264C575.774 46.712 577.55 42.008 577.55 36.152C577.55 30.2 575.774 25.592 572.222 22.328C568.766 19.064 563.438 17.432 556.238 17.432H536.942V55.448Z" stroke="white" stroke-width="10" mask="url(#path-1-outside-1)"/>
      </svg>
    </LoadingContainer>
  );
}

export default Intro;

const LoadingContainer = styled.div`
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-color: #222831;
  #logo {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > path:nth-child(2) {
      stroke-dasharray: 572;
      stroke-dashoffset: 572;
      animation: line-anim 3s ease forwards;
    }
    > path:nth-child(3) {
      stroke-dasharray: 433.7718200683594;
      stroke-dashoffset: 433.7718200683594;
      animation: line-anim 3s ease forwards 0.3s;
    }
    > path:nth-child(4) {
      stroke-dasharray: 572;
      stroke-dashoffset: 572;
      animation: line-anim 3s ease forwards 0.6s;
    }
    > path:nth-child(5) {
      stroke-dasharray: 400;
      stroke-dashoffset: 400;
      animation: line-anim 6s ease forwards 0.9s;
    }
    > path:nth-child(6) {
      stroke-dasharray: 700;
      stroke-dashoffset: 700;
      animation: line-anim 5s ease forwards 0.12s;
    }
    > path:nth-child(7) {
      stroke-dasharray: 575.481201171875;
      stroke-dashoffset: 575.481201171875;
      animation: line-anim 3s ease forwards 0.15s;
    }
    > path:nth-child(8) {
      stroke-dasharray: 515.26416015625;
      stroke-dashoffset: 515.26416015625;
      animation: line-anim 3s ease forwards 0.18s;
    }
    
  }
  @keyframes line-anim {
    to {
      stroke-dashoffset: 0;
    }
  }
`;
import React, { useEffect, useState } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Intro from './components/Intro';

function App() {
  const [intro, setIntro] = useState(true);
  const time = () => {
    setIntro(false);
  };

  setTimeout(time,3300);

  // console off
  useEffect(() => {
    window.oncontextmenu = function () {
      return false;
   }
   document.onkeydown = function (e) { 
       if (window.event.keyCode === 123 ||  e.button===2)    
       return false;
   }
   
  }, []);

  return (
    <>
        {intro ? (
          <Intro/>
        ):(
          <>
            <div className='container'>
              <Header />

              <Footer />
            </div>
          </>
        )}
    </>
  );
}

export default App;
